// const url = "https://pakwheels.digitalelliptical.com/api";
// const url = "http://192.168.100.4:1000/api";
// const url = "https://car-r-us.zologics.com/api";
const url = "https://dashboard.carus-staging.com/api";
// const url = "https://staging-carus.digitalelliptical.com/api";

// const imageUrl = "https://pakwheels.digitalelliptical.com/";
// const imageUrl = "http://192.168.100.4:1000";
const imageUrl = "https://dashboard.carus-staging.com";
// const imageUrl = "https://car-r-us.zologics.com";
// const imageUrl = "https://staging-carus.digitalelliptical.com"

// user Register Routes
export const registerRoute = `${url}/register`;
export const loginRoute = `${url}/login`;
export const resendRoute = `${url}/resend`;
export const verifiedRoute = `${url}/verified`;
export const findEmailRoute = `${url}/Find-Account`;
export const forgotOTPRoute = `${url}/verify`;
export const changePasswordRoute = `${url}/Change_Password`;
// vendor Register Route
export const getCitiesRoute = `${url}/Get/City`;
export const getlocationRoute = `${url}/Get/Location`;
export const addVendorRoute = `${url}/Insert/Vendor`;
export const vendorVerifiedRoute = `${url}/verified/vendor`;
export const resendVendorRoute = `${url}/resend/vendor`;
export const LoginVendorRoute = `${url}/login/vendor`;
export const vendorFindAccount = `${url}/Vendor/Find-Account`
export const vendorVerifyAccount = `${url}/Vendor/Verify`
export const vendorNewPassword = `${url}/Vendor/Change_Password`
// vendor Services Routes
export const UpdateVendorService   =`${url}/Update/Service`
export const GetBrandRoute = `${url}/Get/Brand`
export const UpdateBrandRoute = `${url}/Update/Brand`;
export const UpdatePartRoute = `${url}/Update/Parts/Types`;
export const GetPartsRoute = `${url}/Get/Parts/Types`
export const getSubPartsRoute = `${url}/Get/Parts`
export const GetModelRoute = `${url}/Get/Model`
export const getCountry = `${url}/Get/Country`
export const updateCountry = `${url}/Update/Import/Export`
// services Images Route
export const PartsImagesRoute = `${imageUrl}/uploads/parts/image`;
export const MaintenanceImagesRoute = `${imageUrl}/uploads/maintenance/image`;
export const brandLogoRoute =  `${imageUrl}/uploads/brand/logo`
// Products Route
export const productsRoute = `${url}/product/home`;
export const productRoute = `${url}/product`;
export const bookmarkRoute = `${url}/Product/Wishlist`
export const productHistoryManage = `${url}/User/History/Saved`
// Product Images Route
export const imagesRoute = `${imageUrl}/uploads/product/images`;
export const videosRoute = `${imageUrl}/uploads/product/videos`;
export const cardRoute = `${imageUrl}/uploads/product/card`;
// Maintenance routes
export const getSubMaintenanceRoute = `${url}/Get/Sub/Maintenance`
export const getMaintenanceRoute = `${url}/Get/Maintenance`
export const UpdateMaintenance = `${url}/Update/Maintenance`
// Add Cars Route
export const addCarRoute = `${url}/Insert/Vendor/Product`
export const getAmenitiesRoute = `${url}/Product/Amenities`
export const updateAmenitiesRoute = `${url}/Update/Amenities`
export const insertProductImages = `${url}/Vendor/Product/Insert/Images`
export const updateProductStatus = `${url}/Vendor/Product/Status`
export const editProductRoute = `${url}/Vendor/Product/Edit`
export const productImageDeleteRoute = `${url}/Vendor/Product/Image/Delete`
export const getProductImagesRoute = `${url}/Product/Images`
export const updateProductRoute = `${url}/Vendor/Product/Update`
export const getProductRoute = `${url}/GET/Vendor/Product`
// filters Route
export const filterRoute = `${url}/Filter`
// Vendor Details Route
export const getVendorDetails= `${url}/Vendor/Business/Get`
export const getVendorServices =`${url}/Vendor/Business/Service/listing`
export const updateVendorDetails= `${url}/Vendor/Business/Edit`
export const bannerImageRoute = `${imageUrl}/uploads/vendor/banner`
export const profileImageRoute = `${imageUrl}/uploads/vendor/profile`
export const vendorImageDeleteRoute =`${url}/Vendor/Business/Images/Delete` 
export const vendorChangePassword = `${url}/Vendor/change/password`
// get Vendors Route 
export const getVendors = `${url}/Vendor/Listing`
export const getVendorProfileDetails = `${url}/Vendor/Business/Detail`
export const getVendorMaintenance = `${url}/Vendor/Maintenance`
export const editVendorImages = `${url}/Vendor/Business/Images/Edit`
export const getVendorParts = `${url}/Vendor/Parttypes`
export const vendorImagesRoute= `${imageUrl}/uploads/vendor/images`
export const getVendorReviews = `${url}/GET/Vendor/Review`
export const getVendorSearches = `${url}/Get/Search/History`
// Ads Update Routes
export const updateAdsStatus = `${url}/Make_It_Ads`

// user profile routes
export const GetUserProfileDetails = `${url}/User/Profile`
export const updateUserProfileDetails = `${url}/User/Profile/Edit`
export const userProfilePictureRoute = `${imageUrl}/uploads/users/profile`
export const userAdsListing = `${url}/User/Ads/listing`;
export const userProductListing = `${url}/User/Product/listing`
export const userBookmarkListing = `${url}/Get/Product/Wishlist`
export const userHistoryListing = `${url}/Get/User/History/Saved`
export const deleteHistory =`${url}/Delete/User/History/Saved` 
export const userChangePassword = `${url}/User/change/password`
// Renting 
export const getRentingListing = `${url}/product/ranting`

// Ads APi
export const getVendorAds = `${url}/Get/Ads`


// Review Routes 
export const submitReview = `${url}/Insert/Vendor/Review`
export const submitReviewImages = `${url}/Insert/Vendor/Review/Images/Edit`
export const reviewImagesRoute = `${imageUrl}/uploads/vendor/review/images`


//Blogs Routes

export const getBlogsRoute = `${url}/blog`
export const blogImageRoute = `${imageUrl}/uploads/blog/image`
export const blogSearchRoute = `${url}/blog/search`

// Chat Route

export const getChatRoute = `${url}/Get/Chat/Vendor/Listing`
export const insertNewMessage = `${url}/Insert/New/Message`
export const insertMessage = `${url}/Insert/Message`
export const getMessage = `${url}/Get/Message`
export const getChatsRouteOnVendor= `${url}/Get/Chat/Users/Listing`


export const getChatList = `${url}/Get/Chat/List`
export const getChatMessage = `${url}/Get/Chat`
export const insertChatMessage = `${url}/Insert/Chat`

// inpection form Routes
export const inspectionFormRoute = `${url}/Insert/Product/Inspection/Request`
export const getInspectionReportDetails = `${url}/Get/Inpection/Report`

// Compare Routes

export const insertCompareRoute = `${url}/Insert/Product/Compare`


// live Search Route

export const searchRoute = `${url}/search`
export const updateSearchStatus = ~`${url}/Update/search`
export const liveSearchImageRoute = `${imageUrl}/uploads/search/image`
export const InsertChatRequest = `${url}/Insert/Chat/Request`

export const vendorNotificationsRoute = `${url}/Get/Notification`
export const vendorNotificationsUpdate = `${url}/Update/Notification`
export const vendorRequestStatusUpdate = `${url}/vendor/Accept`
export const vendorMessageImageRoute = `${imageUrl}/uploads/message/image`

// Vendor Filter 
export const vendorFilterRoute = `${url}/Vendor/filter`
// Badges Get
export const vendorBadgesGetRoute  = `${url}/Get/Badge`


// Pricing api
export const AcitivatePlan = `${url}/Plan/Active`


// verify Route api
export const verifyBusinessRoute = `${url}/Vendor/verified/Request`

// all Search api 

export const allSearchRoute = `${url}/Search/All`


// Home Pages APi

export const homepageRoute = `${url}/Home/Page/Stats`
export const carHomepageRoute = `${url}/Home/Car/Stats`
export const serviceHomePage = `${url}/Home/Service`


// PAckage Histroy
export const packageHistroyRoute = `${url}/Vendor/Plan`


// User Token 
export const userTokenUpdate = `${url}/User/Notification/Token/Update`

// Vendor Token 
export const vendorTokenUpdate = `${url}/Vendor/Notification/Token/Update`


// Chat Status
export const insertNotificationStatus = `${url}/Insert/Notification/Status`
export const DeleteNotificationStatus = `${url}/Delete/Notification/Status`
export const DeleteNotificationToken = `${url}/Delete/Notification/Token`